<template>
  <span class="avatar">
    <b-img
      class="avatar__img"
      :class="`avatar-${size}`"
      :src="imgSrc"
      v-bind="$attrs"
      v-on="$listeners" />
  </span>
</template>

<script>
import Avatar, { createSpinner } from '../packs/fake-avatar'

export default {
  props: {
    src : { type: String },
    name: {
      type   : String,
      default: '50x50',
    },
    size: {
      type   : String,
      default: 'md',
    },
  },
  data () {
    return {
      imgSrc: this.avatar
    }
  },
  computed: {
    avatar () {
      return Avatar(this.name)
    },
  },
  watch: { src: 'load'},
  mounted () {
    this.load()
  },
  methods: {
    load () {
      const img  = new Image()
      const vm   = this
      const ori  = this.src
      const fail = this.avatar
      const load = createSpinner(50, 50)

      $(img).one('load', function () {
        vm.imgSrc = ori
      })

      $(img).one('error', function () {
        vm.imgSrc = fail
      })

      vm.imgSrc = load
      img.src   = ori
    }
  }
}
</script>
