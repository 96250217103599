import firebase from 'firebase/app'
import 'firebase/database'

export default function useFirebase (props) {
  const config = {
    apiKey       : props.api_key,
    authDomain   : props.auth_domain,
    databaseURL  : props.database_url,
    storageBucket: props.storage_bucket,
  }

  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }

  return firebase.database()
}
