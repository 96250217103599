import '../core'
import Vue from 'vue'
import App from '../components/login.vue'
import BootstrapVue from 'bootstrap-vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(BootstrapVue)

const captcha = document.getElementById('g-recaptcha-response-data-login')
const siteKey = captcha ? captcha.getAttribute('data-sitekey') : ''

Vue.use(VueReCaptcha, { siteKey: siteKey })

document.addEventListener('DOMContentLoaded', () => {
    const node  = document.getElementById('x-data')
    const props = node && node.getAttribute('data')
        ? JSON.parse(node.getAttribute('data'))
        : {}

    if (node)
        node.remove()

    const app = new Vue({
        el    : '#login',
        render: (h) => h(App, {props}),
    })
})
